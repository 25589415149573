:root {
  --red: #ec0102;
  --black: black;
  --c6c6c6: #454545;
  --dark-purple: #191119;
  --white: white;
}

/* Import Roboto Condensed Light (300) */
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap');

/* Import Roboto Condensed Bold */
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@700&display=swap');

/* Import Roboto Mono Light */
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300&display=swap');

@font-face {
  font-family: 'JWPixel';
  src: url('../fonts/JWPIXEL.OTF') format('opentype');
}

/* Apply the fonts to specific elements or the entire document */
body {
  font-family: 'Roboto Condensed', sans-serif; /* Use Roboto Condensed as the default font for the body */
}

.navbar-no-shadow-container {
  z-index: 5;
  width: 100%;
  max-width: 1450px;
  background-color: rgba(0, 0, 0, 0);
  margin-left: auto;
  margin-right: auto;
  padding: 5vh 0 0;
}

.container-regular {
  width: 100%;
  max-width: 1450px;
  min-height: 30px;
  margin-left: auto;
  margin-right: auto;
  padding-right: 20px;
}

.navbar-wrapper {
  justify-content: center;
  align-items: center;
  display: flex;
}

.nav-menu-wrapper {
  background-color: rgba(0, 0, 0, 0);
}

.nav-menu {
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 0;
  padding-bottom: 0;
  padding-left: 0;
  display: flex;
}

.nav-link {
  color: #1a1b1f;
  letter-spacing: 0.25px;
  margin-left: 5px;
  margin-right: 5px;
  padding: 5px 10px;
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;
}

.nav-link:hover {
  color: rgba(26, 27, 31, 0.75);
}

.nav-link:focus-visible {
  outline-offset: 0px;
  color: #0050bd;
  border-radius: 4px;
  outline: 2px solid #0050bd;
}

.nav-link[data-wf-focus-visible] {
  outline-offset: 0px;
  color: #0050bd;
  border-radius: 4px;
  outline: 2px solid #0050bd;
}

.nav-dropdown {
  margin-left: 5px;
  margin-right: 5px;
}

.nav-dropdown-toggle {
  letter-spacing: 0.25px;
  padding: 5px 30px 5px 10px;
  font-size: 14px;
  line-height: 20px;
}

.nav-dropdown-toggle:hover {
  color: rgba(26, 27, 31, 0.75);
}

.nav-dropdown-toggle:focus-visible {
  outline-offset: 0px;
  color: #0050bd;
  border-radius: 5px;
  outline: 2px solid #0050bd;
}

.nav-dropdown-toggle[data-wf-focus-visible] {
  outline-offset: 0px;
  color: #0050bd;
  border-radius: 5px;
  outline: 2px solid #0050bd;
}

.nav-dropdown-icon {
  margin-right: 10px;
}

.nav-dropdown-list {
  background-color: #fff;
  border-radius: 12px;
}

.nav-dropdown-list.w--open {
  padding-top: 10px;
  padding-bottom: 10px;
}

.nav-dropdown-link {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
}

.nav-dropdown-link:focus-visible {
  outline-offset: 0px;
  color: #0050bd;
  border-radius: 5px;
  outline: 2px solid #0050bd;
}

.nav-dropdown-link[data-wf-focus-visible] {
  outline-offset: 0px;
  color: #0050bd;
  border-radius: 5px;
  outline: 2px solid #0050bd;
}

.nav-button-wrapper {
  margin-left: 0;
}

.button-primary {
  color: var(--red);
  letter-spacing: 0;
  text-transform: uppercase;
  background-color: rgba(0, 0, 0, 0);
  border-bottom: 2px rgba(0, 0, 0, 0);
  margin-right: 0;
  padding: 3px 2px 3px 15px;
  font-family:
    Roboto Condensed,
    sans-serif;
  font-size: 20px;
  font-weight: 300;
  line-height: 20px;
  transition: all 0.2s;
}

.button-primary:hover {
  border-bottom: 0px solid var(--red);
  background-color: var(--red);
  color: var(--black);
}

.button-primary:active {
  background-color: #43464d;
}

.button-primary.w--current {
  background-color: var(--red);
  color: var(--black);
  padding-right: 2px;
  font-weight: 600;
}

.menu-button {
  padding-right: 0;
}

.menu-button.w--open {
  background-color: rgba(0, 0, 0, 0);
  padding-bottom: 18px;
}

.icon {
  color: #ec0102;
  -webkit-text-stroke-color: var(--red);
  font-size: 50px;
  line-height: 25px;
}

.base {
  background-image: url('../images/BaseBg.jpg');
  background-position: 50%;
  background-size: cover;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.note {
  max-width: 10ch;
  min-height: 100px;
  min-width: 100px;
  float: none;
  clear: none;
  cursor: pointer;
  background-color: #9c99d7;
  margin-left: 200px;
  padding: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
}

.note._2 {
  display: none;
  left: 80%;
}

.h2 {
  color: var(--red);
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 0;
  font-family:
    Roboto Condensed,
    sans-serif;
  font-size: 22px;
  font-weight: 600;
}

.h2.mbot {
  margin-bottom: 20px;
}

.h2.mbot.c1 {
  margin-bottom: 0;
  display: none;
}

.paragraph {
  color: var(--black);
  font-family:
    Roboto Condensed,
    sans-serif;
  font-weight: 300;
}

.paragraph.red {
  color: var(--red);
  font-size: 22px;
  font-weight: 300;
  line-height: 27px;
  text-transform: uppercase;
}

.paragraph.red4 {
  color: var(--red);
  font-size: 18px;
  font-weight: 300;
  line-height: 27px;
}

.paragraph.red1 {
  color: var(--red);
  font-size: 22px;
  font-weight: 300;
  line-height: 27px;
}

.paragraph.red2 {
  color: var(--red);
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
  margin-bottom: 0px;
}
.paragraph.red3 {
  color: var(--red);
  font-size: 30px;
  font-weight: 300;
  line-height: 27px;
  margin-bottom: 0px;
  margin-top: 15px;
}

.paragraph.bold {
  color: var(--red);
  font-size: 22px;
  font-weight: 600;
  line-height: 27px;
  text-transform: uppercase;
}

.paragraph.lower {
  color: var(--red);
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
}

.paragraph.low {
  color: var(--red);
  font-size: 22px;
  line-height: 27px;
  font-family:
    Roboto Condensed,
    sans-serif;
  font-weight: 300;
}

.c-box {
  z-index: 2;
  max-width: 50ch;
  min-height: 10vh;
  border: 1px solid var(--red);
  object-fit: fill;
  background-color: rgba(0, 0, 0, 0.5);
  background-image: none;
  flex-direction: column;
  align-items: flex-start;
  padding: 50px 35px;
  line-height: 2040px;
  display: flex;
  position: absolute;
  top: 27%;
  left: 65%;
}

.c-text {
  background-color: var(--red);
  object-fit: fill;
  margin-bottom: 25px;
  padding-left: 2px;
  padding-right: 2px;
}

.h5 {
  color: var(--black);
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 5px;
  font-family:
    Roboto Condensed,
    sans-serif;
  font-size: 8px;
  line-height: 12px;
}

.close-btn {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: auto;
  left: auto;
  right: 0%;
}

.closebtn {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  z-index: 99;
}

.x1 {
  width: 2px;
  height: 30px;
  background-color: var(--red);
  position: absolute;
  transform: rotate(45deg);
}

.x1.r {
  height: 30px;
  transform-origin: 50%;
  transform: rotate(-45deg);
}

.bot-bar {
  width: 100%;
  position: absolute;
  bottom: 10vh;
  z-index: 10;
  padding-left: 30px;
}

.bot-bar._2 {
  z-index: 100;
  display: flex;
  padding-left: 20px;
}

.botbar {
  background-color: rgba(0, 0, 0, 0);
  display: block;
}

.bot-bar-wrapper {
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
}

.bot-menu {
  grid-column-gap: 100px;
  grid-row-gap: 100px;
  align-items: center;
  margin-bottom: 0;
  padding-bottom: 0;
  padding-left: 0;
  display: flex;
  justify-content: space-between;
}

.section-1 {
  background-image: url('../images/270w.gif');
  background-position: 50%;
  background-size: cover;
  padding-left: 20px;
  padding-right: 20px;
  display: block;
  position: fixed;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
  overflow: hidden;
}

.section-2 {
  height: 100vh;
  background-image: url('../images/270w.png'), url('../images/270w.gif');
  background-position: 50%, 50%;
  background-size: cover, cover;
  display: block;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
  overflow: visible;
}

.redmist {
  position: absolute;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  background-color: rgb(255, 0, 0);
  mix-blend-mode: overlay;
  pointer-events: none;
  animation: fadeInOut 1s ease forwards; /* Apply the keyframe animation */
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  } /* Start with opacity 0 */
  50% {
    opacity: 1;
  } /* Fade in */
  100% {
    opacity: 0;
  } /* Fade out */
}
.content-frame {
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section {
  z-index: -1;
  width: 100%;
  height: 100%;
  position: relative;
}

.content-wrapper {
  display: block;
  position: static;
}

.btn-1 {
  color: var(--c6c6c6);
  font-family:
    Roboto Condensed,
    sans-serif;
  font-size: 22px;
  font-weight: 400;
}

.btn-1.on {
  color: var(--red);
  text-shadow: 0 1px 20px var(--red);
  font-size: 22px;
  font-weight: 600;
}

.bot-btn-wrapper {
  cursor: pointer;
  margin-left: 0;
}

.overlay {
  z-index: 11;
  background-color: #191119;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
  opacity: 0;
  pointer-events: none; /* Allows clicks to go through the overlay */
  transition: opacity 1s ease; /* Transition over 1 second with ease */
}

.overlay.overlay-visible {
  opacity: 0.9; /* Set the target opacity */
}

.people {
  z-index: 10;
  width: 200vw;
  height: 250vh;
  max-width: 200vw;
  display: block;
  position: absolute;
  top: -181px;
}

.bot-menu-2 {
  grid-column-gap: 100px;
  grid-row-gap: 100px;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0;
  padding-bottom: 0;
  padding-left: 0;
  display: flex;
}

.bg-s1 {
  background-image: url('../images/wide_center.jpg');
  background-position: 50%;
  background-size: cover;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.bg-v1 {
  background-image: url('../images/wide_center.jpg');
  background-position: 50%;
  background-size: cover;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
  display: flex;
  align-items: center;
}

.bg-v1-empty {
  background-image: url('../images/room_clean_wide.png');
  background-position: center;
  background-size: cover;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gif1 {
  background-image: url('../images/gif1.gif');
  background-position: 50%;
  background-size: cover;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.gif2 {
  background-image: url('../images/gifbtc.gif');
  background-position: 50%;
  background-size: cover;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
  display: flex;
  align-items: center;
}

.gif3 {
  background-image: url('../images/gif3.gif');
  background-position: 50%;
  background-size: cover;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  display: flex;
  align-items: center;
  right: 0%;
}

.bg-video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.bg-video video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Fallback background styles */
.bg-video {
  background:
    linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url('../images/wide_center.jpg') center/cover no-repeat;
}

.bg-video-balance {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.bg-video-balance video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Fallback background styles */
.bg-video-balance {
  background:
    linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url('../images/balancefallback.png') center/cover no-repeat;
}

.bg-video-home {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.bg-video-home video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Fallback background styles */
.bg-video-home {
  background:
    linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url('../images/fallhome.png') center/cover no-repeat;
}

.bg-s2 {
  background-image: url('../images/RUMLEFT.png');
  background-position: 50%;
  background-size: cover;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.bot-bar-2 {
  z-index: 100;
  width: 100%;
  position: static;
  bottom: 100px;
}

.bot-bar-2._2 {
  z-index: 100;
  display: flex;
}

.image {
  width: 200px;
  height: 200px;
  position: absolute;
  top: auto;
  bottom: 0%;
  left: 0%;
  right: auto;
}

.botbar1 {
  width: 100%;
  position: absolute;
  bottom: 120px;
}

.botbar1._2 {
  z-index: 100;
  display: flex;
}

.bot-menu-copy {
  grid-column-gap: 100px;
  grid-row-gap: 100px;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0;
  padding-bottom: 0;
  padding-left: 0;
  display: flex;
}

.pre-loader {
  z-index: 200;
  background-color: var(--dark-purple);
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.loader-wrapper {
  z-index: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -5%;
  display: flex;
  position: relative;
}

.logo {
  width: 15vw;
}

.font-animation {
  width: 50%;
}

.tr-dark {
  z-index: 2;
  background-image: linear-gradient(to right, #000 29%, rgba(0, 0, 0, 0) 88%, rgba(0, 0, 0, 0));
  display: block;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.r1-section {
  z-index: 1;
  background-position: 50%;
  background-size: cover;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
  overflow: hidden;
}

.v1-section {
  z-index: 1;
  background-position: 50%;
  background-image: url('../images/room_clean_wide.png');
  background-size: cover;
  padding-left: 20px;
  padding-right: 20px;
  display: block;
  position: fixed;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.r2-section-gif1 {
  z-index: 1;
  background-image: url('../images/gif1.gif');
  background-position: 50%;
  background-size: cover;
  padding-left: 20px;
  padding-right: 20px;
  display: block;
  position: fixed;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
  overflow: hidden;
}

.r2-section-gif2 {
  z-index: 1;
  background-image: url('../images/gif2.gif');
  background-position: 50%;
  background-size: cover;
  padding-left: 20px;
  padding-right: 20px;
  display: block;
  position: fixed;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
  overflow: hidden;
}

.r2-section-gif3 {
  z-index: 1;
  background-image: url('../images/gif3.gif');
  background-position: 50%;
  background-size: cover;
  padding-left: 20px;
  padding-right: 20px;
  display: block;
  position: fixed;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
  overflow: hidden;
}

.b-box {
  z-index: 3;
  max-width: 60ch;
  min-height: 10vh;
  border: 1px solid var(--red);
  object-fit: fill;
  background-color: rgba(0, 0, 0, 0.75);
  background-image: none;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 10px 40px 10px;
  display: flex;
  position: static;
  top: 27%;
  left: 65%;
  width: 100%;
}

.e-box {
  z-index: 2;
  max-width: 60ch;
  width: 100%;
  border: 1px solid var(--red);
  object-fit: fill;
  background-color: rgba(0, 0, 0, 0.75);
  background-image: none;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 10px 10px 10px;
  display: flex;
  position: static;
  top: 27%;
  left: 65%;
}

.btn_connect {
  background-color: transparent;
  color: var(--red);
  font-family:
    Roboto Condensed,
    sans-serif;
  font-size: 22px;
  position: relative;
  padding-top: 5px;
  padding-bottom: 5px;
}

.btn_connect:hover {
  background-color: var(--red);
  color: black;
  font-family:
    Roboto Condensed,
    sans-serif;
  font-size: 22px;
  position: relative;
  padding-top: 5px;
  padding-bottom: 5px;
}

.max-btn {
  border: 1px solid var(--red);
  padding: 3px 5px;
  font-size: 16px;
  cursor: pointer; /* Change the cursor to pointer on hover */
  font-family:
    Roboto Condensed,
    sans-serif;
  font-weight: 600;
  background-color: black;
  color: var(--red);
}

.max-btn:hover {
  border: 1px solid var(--red);
  background-color: var(--red);
  color: black;
  font-size: 16px;
}

.btn_connect_clean {
  color: var(--red);
  background-color: rgba(0, 0, 0, 0);
  font-weight: light;
  font-family:
    Roboto Condensed,
    sans-serif;
  font-size: 22px;
  margin-top: 4px;
  transition: padding 0.3s ease;
}

.btn_connect_clean:hover {
  color: var(--red);
  background-color: var(--red);
  font-weight: light;
  font-family:
    Roboto Condensed,
    sans-serif;
  font-size: 22px;
  margin-top: 4px;
  padding-left: 25px;
}

.bbox-wrapper {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: absolute;
  right: 20%;
  z-index: 2;
}

.box-eligible {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: absolute;
  width: 100%;
  max-width: 60ch;
  margin-top: -150px;
}

.bbox-wrapper-right {
  flex-direction: column;
  align-items: flex-end;
  margin-top: -10vh;
  margin-left: 15vw;
  display: flex;
  position: absolute;
  bottom: auto;
  left: 50%;
  right: auto;
}

.right {
  right: 10%;
}

.bbox-wrapper-connect {
  flex-direction: column;
  align-items: flex-start;
  margin-top: -150px;
  margin-left: 0px;
  display: flex;
  position: absolute;
  top: 50%;
  bottom: auto;
  left: 50%;
  right: auto;
}

.bbox-wrapper-side {
  flex-direction: column;
  align-items: flex-end;
  /* margin-top: -220px; */
  /* margin-left: -500px; */
  display: flex;
  position: absolute;
  top: 30%;
  bottom: auto;
  left: auto;
  right: 70%;
}

.p-red {
  color: var(--red);
  text-transform: none;
  font-family:
    Roboto Condensed,
    sans-serif;
  font-size: 22px;
  font-weight: 300;
  line-height: 27px;
}

.p-red.red {
  color: var(--red);
  font-size: 22px;
  font-weight: 300;
  line-height: 27px;
}

.p-red.low {
  font-size: 6px;
  line-height: 10px;
}

.p-wallet {
  color: var(--red);
  text-transform: none;
  font-family:
    Roboto Condensed,
    sans-serif;
  font-size: 22px;
  font-weight: 300;
  line-height: 27px;
}

.p-wallet.red {
  color: var(--red);
  font-size: 22px;
  font-weight: 300;
  line-height: 27px;
}

.p-wallet.low {
  font-size: 6px;
  line-height: 10px;
}

.p-wallet.c1 {
  text-transform: uppercase;
  font-size: 15px;
  line-height: 20px;
}

.state-eth-on {
  flex-direction: column;
  display: block;
}

.state-eth-onb,
.no-state-btn {
  flex-direction: column;
  display: flex;
}

.eth-connected,
.btc-connected {
  margin-top: 20px;
  display: none;
}

.btcbtn {
  background-color: var(--red);
  color: var(--black);
  font-family:
    Roboto Condensed,
    sans-serif;
  font-size: 22px;
  font-weight: 600;
  position: relative;
}

.btcbtn.btc,
.btcbtn.rg {
  color: var(--red);
  background-color: rgba(0, 0, 0, 0);
  font-weight: 300;
}

.eth-btn {
  background-color: var(--red);
  color: var(--black);
  font-family:
    Roboto Condensed,
    sans-serif;
  font-size: 22px;
  font-weight: 600;
  position: relative;
}

.eth-btn.btc,
.eth-btn.rg {
  color: var(--red);
  background-color: rgba(0, 0, 0, 0);
  font-weight: 300;
}

.register {
  background-color: var(--red);
  color: var(--black);
  font-family:
    Roboto Condensed,
    sans-serif;
  font-size: 22px;
  font-weight: 600;
  position: relative;
}

.register.btc {
  color: var(--red);
  background-color: rgba(0, 0, 0, 0);
  font-weight: 300;
}

.register.rg {
  color: var(--red);
  background-color: rgba(0, 0, 0, 0);
  font-weight: 300;
  display: none;
}

.btc-btn {
  background-color: transparent;
  color: var(--black);
  font-family:
    Roboto Condensed,
    sans-serif;
  font-size: 22px;
  font-weight: 400;
  position: relative;
}

.btc-btn.btc {
  color: var(--red);
  background-color: rgba(0, 0, 0, 0);
  font-weight: 300;
}

.btc-btn.bt {
  display: none;
}

.btc-btn.rg {
  color: var(--red);
  background-color: rgba(0, 0, 0, 0);
  font-weight: 300;
}

.logo-wrapper {
  position: relative;
  z-index: 10;
  max-width: 1450px;
  padding-left: 20px;
  padding-top: 80px;
  width: 100%;
  max-height: 100px;
}

.audio-button {
  position: relative;
  z-index: 1;
  width: 25px;
  height: 25px;
  background: var(--red);
}

.audio-button.playing {
  background: var(--red);
}

.audio-button.stopped {
  background: #454545;
}

.audio-button-top {
  position: relative;
  z-index: 100;
  width: 50px;
  height: 50px;
  background: transparent;
}

.audio-wrapper {
  position: absolute;
  bottom: 5vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11;
  right: 5vh;
}

.footer-stage {
  position: absolute;
  font-size: 1rem;
  bottom: 10vh;
  z-index: 1;
  margin: auto;
  color: #4c4c56;
  width: 100%;
  left: 0;
  display: flex;
  justify-content: center;
  white-space: pre-wrap;
  font-family: 'JWPixel', sans-serif;
}

.footer-tc {
  position: absolute;
  font-size: 1rem;
  bottom: 2vh;
  z-index: 1;
  margin: auto;
  color: #4c4c56;
  width: 100%;
  left: 0;
  display: flex;
  justify-content: center;
  white-space: pre-wrap;
}

.side-text {
  font-family: 'JWPixel', sans-serif;
  color: var(--red);
  font-size: 1rem;
  font-weight: 400;
  text-align: right;
  margin-bottom: 0px;
}

.typewriter {
  overflow: hidden;
  animation: typewriter 1s steps(20) 1s forwards;
  animation-fill-mode: forwards;
}

@keyframes typewriter {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.redbold {
  color: var(--red);
}

a {
  text-decoration: underline;
  color: #ec0102;
}

.mh-logo {
  width: 200px;
}

/* NAVBAR */

.nav-wrapper {
  position: absolute;
  z-index: 2;
  width: 100%;
  max-width: 1450px;
  top: 5%;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: space-between;
  align-items: flex-start;
  transition: opacity 0.5s ease-in-out;
}

.burger {
  width: 30px;
  padding-bottom: 10px;
  padding-top: 15px;
  cursor: pointer;
}

.burger-box {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.burger-menu {
  display: flex;
  row-gap: 0px;
  flex-direction: column;
  align-items: flex-end;
}

.btn-burger {
  color: var(--red);
  font-family:
    Roboto Condensed,
    sans-serif;
  font-size: 20px;
  padding: 5px;
  font-weight: 400;
  margin-right: -5px;
}

.nav-a {
  text-decoration: none;
}

.nav-a:hover {
  text-decoration: none;
  color: black;
}

.btn-burger.on {
  color: black;
  font-family:
    Roboto Condensed,
    sans-serif;
  font-size: 20px;
  padding: 5px;
  font-weight: 400;
  margin-right: -5px;
}

.btn-burger:hover {
  color: black;
  background: var(--red);
  font-family:
    Roboto Condensed,
    sans-serif;
  font-size: 20px;
  font-weight: 400;
}

/* HOME page elments */

.home-section {
  z-index: 1;
  background-position: 50%;
  background-size: cover;
  background: url('../images/room_clean_wide.png') center/cover no-repeat;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  position: fixed;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}

.trinity-section {
  z-index: 1;
  background-position: 50%;
  background-size: cover;
  background: url('../images/room_clean_wide.png') center/cover no-repeat;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  position: fixed;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}

.Genealogy-section {
  z-index: 1;
  background-position: 50%;
  background-size: cover;
  background: url('../images/Genealogy.jpg') center/cover no-repeat;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  position: fixed;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}

.mintend-section {
  z-index: 1;
  background-position: 50%;
  background-size: cover;
  background: url('../images/room_clean_wide.png') center/cover no-repeat;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  position: fixed;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.amount-wrapper {
  position: absolute;
    bottom: 17vh;
    max-width: 35vw;
    margin-left: 60px;
    max-width: 500px;
}

.mintend-image {
  max-width: 50vw;
  max-height: 35vh;
  z-index: 3;
  position: absolute;
}

.mintend-image-gif {
  max-width: 50vw;
  max-height: 35vh;
  z-index: 2;
  position: absolute;
}

.endimage {
  position: relative;
  width: 50vw;
  height: 35vh;
  display: flex;
  justify-content: center;
}

.trinity-wrapper {
  z-index: 1;
  background-position: 50%;
  background-size: cover;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  position: fixed;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}

.t-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.t-box {
  width: 30%;
  cursor: pointer;
  position: relative;
}

.t-box.selected {
  z-index: 106;
}

.t-box:hover img {
  filter: brightness(115%);
  transition: filter 0.3s ease-in-out;
}

.tbox-left {
  background-image: url('../images/roomleft.jpg');
  background-position: 50%;
  background-size: cover;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tbox-mid {
  background-image: url('../images/roommid.jpg');
  background-position: 50%;
  background-size: cover;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tbox-right {
  background-image: url('../images/roomright.jpg');
  background-position: 50%;
  background-size: cover;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.t-box-content-2 {
  margin-right: -50vw;
  padding: 20px;
  border: 1px solid var(--red);
  background: rgba(0, 0, 0, 0.75);
  z-index: 150;
}

.t-box-content-1 {
  padding: 20px;
  border: 1px solid var(--red);
  background: rgba(0, 0, 0, 0.75);
  z-index: 150;
  max-width: 50ch;
}

.t-box-end-screen {
  padding: 20px;
  border: 1px solid var(--red);
  background: rgba(0, 0, 0, 0.75);
  z-index: 150;
  bottom: 25vh;
  max-width: 800px;
}

.t-box-mintend {
  padding: 20px;
  border: 1px solid var(--red);
  background: rgba(0, 0, 0, 0.75);
  z-index: 150;
  bottom: 25vh;
  position: absolute;
  max-width: 1080px;
}

.t-box-end-screen {
  padding: 20px;
  border: 1px solid var(--red);
  background: rgba(0, 0, 0, 0.75);
  z-index: 150;
  bottom: 25vh;
}

.t-box-mintend {
  padding: 20px;
  border: 1px solid var(--red);
  background: rgba(0, 0, 0, 0.75);
  z-index: 150;
  bottom: 25vh;
  position: absolute;
  max-width: 1080px;
}

.t-box-content-3 {
  padding: 20px;
  border: 1px solid var(--red);
  background: rgba(0, 0, 0, 0.75);
  z-index: 150;
}

.additional-content {
  position: absolute;
  display: flex;
  z-index: 2;
  background: #0000008f;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  opacity: 0; /* Start with 0 opacity */
  transition: opacity 0.5s ease; /* Apply transition to opacity property */
}

.additional-content.visible {
  opacity: 1; /* Change opacity to 1 when the .visible class is applied */
  animation: ease 0.5s ease forwards; /* Add fade-in animation */
}

.additional-content.fade-out {
  opacity: 0; /* Change opacity to 0 when the .fade-out class is applied */
}

.t-box {
  -webkit-tap-highlight-color: transparent; /* For iOS */
  -webkit-touch-callout: none; /* Disable touch callout (long press menu) */
  -ms-touch-action: manipulation; /* Disable default touch actions in IE10+ */
  touch-action: manipulation; /* Disable default touch actions */
}

.home-box {
  flex-direction: column;
  align-items: stretch;
  display: flex;
  position: absolute;
  margin: auto;
  row-gap: 0px;
  margin-left: 60px;
}

.home-box-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.current-box {
  background: #000000d6;
  border: 1px solid var(--red);
  padding: 20px 40px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
}

.current-box h2 {
  color: white;
  font-size: 3rem;
}

.current-box h2:hover {
  color: var(--red);
  font-size: 3rem;
}

.current-box p {
  color: #d9d9d9;
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 0.3;
  font-family: 'Roboto Mono', monospace;
  margin-bottom: 0px;
  margin-left: 30px;
  text-transform: uppercase;
}

.current-box-off {
  background: transparent;
  padding: 5px 40px;
  display: flex;
}

.current-box-off h2 {
  color: var(--red);
  font-size: 3rem;
}

@media screen and (max-width: 991px) {
  .nav-menu-wrapper {
    background-color: rgba(0, 0, 0, 0);
  }

  .nav-menu {
    background-color: rgba(0, 0, 0, 0);
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    padding-left: 0;
    display: flex;
  }

  .nav-link {
    padding-left: 5px;
    padding-right: 5px;
  }

  .nav-dropdown-list.shadow-three.w--open {
    position: absolute;
  }

  .nav-button-wrapper {
    width: 100%;
    margin-left: 0;
  }

  .menu-button {
    padding: 12px;
  }

  .menu-button.w--open {
    color: #fff;
    background-color: rgba(0, 0, 0, 0);
  }

  .base {
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .note {
    left: 83%;
  }

  .c-box {
    width: 95%;
    max-width: 100ch;
    align-items: flex-start;
    top: 15%;
    left: auto;
    right: auto;
  }

  .bot-bar-wrapper {
    background-color: rgba(0, 0, 0, 0);
  }

  .bot-menu {
    background-color: rgba(0, 0, 0, 0);
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    padding-left: 0;
    display: flex;
  }

  .bbox-wrapper {
    margin-left: 0px;
  }

  .box-eligible {
    margin-top: -100px;
    width: 95%;
  }
  .section-1,
  .section-2 {
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .bot-btn-wrapper {
    width: 100%;
    margin-left: 0;
  }

  .bot-menu-2,
  .bot-menu-copy {
    background-color: rgba(0, 0, 0, 0);
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    padding-left: 0;
    display: flex;
  }

  .r1-section,
  .r2-section {
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .b-box {
    width: 100%;
    max-width: 100ch;
    align-items: flex-start;
    top: 15%;
    left: auto;
    right: auto;
  }
  .list-wrapper {
    padding: 15px;
    position: relative;
    width: 35vw;
    height: 35vw;
    max-width: 50vh;
    max-height: 50vh;
    margin-top: -10vh;
    margin-left: -150%;
  }

  .hound-purchased {
    font-size: 0.75rem;
  }
}

.tilt {
  display: none;
}

.eligible-box {
  display: flex;
  align-items: center;
  padding-top: 20px;
}

.eligible-box img {
  margin-right: 20px;
}

.image-with-margin {
  margin-right: 20px;
  max-width: 50px; /* optional, set the maximum width as needed */
  max-height: 50px;
}

.center {
  text-align: center;
}

.mobile-content {position: relative;
z-index: 10;
display: flex;
flex-direction: column;
align-items: center;
background: #000000c7;
padding: 50px 25px;
border: solid 1px #ec0102;
}

.list-item-placeholder {}

/* TILT YOUR PHONE*/
@media screen and (max-width: 905px) {

  .tilt {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    background: black;
    z-index: 100;
    justify-content: center;
    align-items: center;
    color: white;
    text-transform: uppercase;
    color: #ec0102;
    font-family: Roboto Condensed, sans-serif;
    font-size: 15px;
    font-weight: 400;
    flex-direction: column;
    gap: 15px;
    padding: 50px;
    text-align: center;
  }




  .content-frame {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.verification-section {
  z-index: 1;
  background-position: 50%;
  background-size: cover;
  display: flex;
  position: fixed;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}

.verification-text {
  font-size: 5rem;
  color: var(--red);
  line-height: 1;
  max-width: 1410px;
}

.complete-box {
  width: 100vw;
  position: relative;
  z-index: 10;
  background: #000000cf;
  border-top: 2px solid var(--red);
  border-bottom: 2px solid var(--red);
  height: 45vh;
  margin-top: -4vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.con-flex {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.calculation-content {
  z-index: 101;
  margin-top: -40px;
  max-width: 1410px;
  min-width: 60vw;
  min-height: 50vh;
  justify-content: center;
  border: 1px solid var(--red);
  object-fit: fill;
  background-color: rgba(0, 0, 0, 0.95);
  background-image: none;
  flex-direction: column;
  align-items: center;
  padding: 25px 19px 25px 25px;
  display: flex;
  position: static;
  overflow: scroll;
  overflow-x: hidden; /* Hide the horizontal scrollbar */
  overflow-y: scroll; /* Show the vertical scrollbar */
  /* Customize the vertical scrollbar */
  scrollbar-width: thin; /* "thin" for modern browsers, "auto" for others */
  scrollbar-color: #ec0102 transparent; /* Change the scrollbar color */

  /* For Webkit browsers (Chrome, Safari) */
  &::-webkit-scrollbar {
    width: 4px; /* Set the width of the scrollbar */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ec0102; /* Set the color of the scrollbar thumb */
  }
}

.calculation-content H2 {
  font-family:
    Roboto Condensed,
    sans-serif;
  font-size: 32px;
  text-transform: uppercase;
  color: var(--red);
  margin-top: 10px;
  letter-spacing: normal;
}

.calculation-content h3 {
  font-family:
    Roboto Condensed,
    sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  color: var(--red);
  letter-spacing: 2px;
}

.calculation-content p {
  font-family:
    Roboto Condensed,
    sans-serif;
  font-size: 20px;
  font-weight: 300;
  line-height: 26px;
  color: var(--red);
}

.flex-con {
  width: 80%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 200ch;
}

/*HORIZONTAL MOBILE*/

@media screen and (orientation: landscape) and (max-width: 1000px) {
  /* Your CSS rules for landscape mode go here */

  .home-box {
    flex-direction: column;
    align-items: stretch;
    display: flex;
    position: absolute;
    margin: auto;
    row-gap: 0px;
    margin-left: 60px;
  }

  .hound-purchased {
    font-size: 0.75rem !important;
  }

  .hound-id {
    font-size: 0.75rem !important;
    padding: 0px !important;
  }

  .four-columns {
    width: calc(24.1% - 4px) !important;
    margin-left: 1px;
  }

  .list-item img {
    width: 100%;
    height: auto;
    display: block;
  }

  .list-wrapper {
    padding: 7px !important;
    margin-left: -150% !important;
  }

  .current-box p {
    font-size: 0.75rem;
  }

  .current-box h2 {
    font-size: 1.25rem;
  }

  .current-box-off h2 {
    font-size: 1.25rem;
  }

  .current-box {
    padding: 10px;
  }

  .paragraph.red3 {
    font-size: 16px;
  }

  .paragraph.red2 {
    font-size: 14px;
    line-height: 18px;
  }

  .current-box-off {
    padding: 0px;
    padding-left: 20px;
  }

  .bot-menu {
    margin-left: 0px !important;
  }

  .verification-text {
    font-size: 2rem;
    color: var(--red);
    line-height: 1;
    max-width: 1410px;
  }

  .calculation-content {
    margin-top: 0px !important;
    width: 100%;
    max-height: 80vh;
    padding: 10px;
  }

  .calculations {
    width: 95% !important;
    margin-top: 10px !important;
  }

  .calculation-container {
    width: 70vw !important;
  }

  .calculation-content p {
    font-family:
      Roboto Condensed,
      sans-serif;
    font-size: 14px;
    font-weight: 300;
    line-height: 18px;
    color: var(--red);
  }

  .calculation-sum.bordertop {
    color: #fff;
    font-size: 16px;
    text-align: center;
  }

  .calculation-content H2 {
    font-size: 26px;
    letter-spacing: normal;
    margin-top: 10px;
  }

  .amount-controls {
    align-items: center;
    display: inline-flex;
    gap: 10px;
    justify-content: center;
    margin-bottom: 10px;
  }

  .amount-controls-tiered {
    align-items: center;
    display: inline-flex;
    gap: 10px;
    justify-content: center;
    margin-bottom: 10px;
  }

  .bordertop {
    border: 1px solid #ec0102;
    border: 1px solid var(--red);
    margin-top: 5px;
    padding: 5px;
  }

  .image-with-margin {
    margin-right: 20px;
    max-width: 50px;
    max-height: 40px !important;
  }

  .audio-wrapper {
    bottom: 20px;
  }

  .audio-button {
    position: relative;
    z-index: 1;
    width: 15px;
    height: 15px;
    background: var(--red);
  }

  .content-frame {
    display: flex !important;
    justify-content: center;
  }

  .flex-con {
    width: 80%;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    padding-left: 0px;
    padding-right: 0px;
  }

  .r2-section-gif1 {
    display: flex;
    align-items: center;
  }

  .logo-wrapper {
    padding-top: 20px;
  }

  .mh-logo {
    width: 125px;
  }

  .popup-content {
    padding: 10px 10px 10px 10px !important;
    overflow: scroll !important;
    margin-top: 0px !important;
    z-index: 101 !important;
  }

  .popup-content h2 {
    font-size: 18px !important;
    line-height: 24px !important;
    margin-top: 0px !important;
  }

  .popup-content p {
    font-size: 14px !important;
    line-height: 18px !important;
  }

  .popup-content h3 {
    font-size: 14px !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    line-height: 20px !important;
  }

  .btn-1.on {
    color: var(--red);
    text-shadow: 0 1px 20px var(--red);
    font-size: 15px;
    font-weight: 600;
  }

  .btn-1 {
    color: var(--c6c6c6);
    font-family:
      Roboto Condensed,
      sans-serif;
    font-size: 15px;
    font-weight: 400;
  }

  .bot-menu {
    background-color: rgba(0, 0, 0, 0);
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    padding-left: 0;
    display: flex;
    grid-column-gap: 25px;
    grid-row-gap: 25px;
  }

  .bot-bar {
    width: 100%;
    position: absolute;
    bottom: 20px;
    z-index: 10;
  }

  .navbar-wrapper {
    justify-content: flex-start;
    align-items: center;
    display: flex;
  }

  .bbox-wrapper {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 0px;
    margin-left: 0px;
    display: flex;
    position: absolute;
    top: auto;
    bottom: auto;
    left: 50%;
    right: auto;
  }

  .b-box {
    z-index: 3;
    max-width: 60ch;
    min-height: 10vh;
    border: 1px solid var(--red);
    object-fit: fill;
    background-color: rgba(0, 0, 0, 0.75);
    background-image: none;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 10px 20px 10px;
    display: flex;
    position: static;
    top: 27%;
    left: 65%;
  }

  .h2.mbot {
    margin-bottom: 10px;
  }

  .h2 {
    color: var(--red);
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 0;
    font-family:
      Roboto Condensed,
      sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
  }

  .paragraph.red {
    color: var(--red);
    font-size: 18px;
    font-weight: 300;
    line-height: 22px;
    text-transform: uppercase;
  }

  .btn_connect {
    font-size: 18px;
  }

  .btn_connect:hover {
    font-size: 18px;
  }

  .btn_connect_clean {
    font-size: 18px;
  }

  .btn_connect_clean:hover {
    font-size: 18px;
  }

  .bbox-wrapper-connect {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 0px;
    margin-left: 0px;
    display: flex;
    position: absolute;
    top: auto;
    bottom: auto;
    left: 50%;
    right: auto;
    min-width: 40%;
  }

  .bbox-wrapper-side {
    flex-direction: column;
    align-items: flex-end;
    margin-top: -100px;
    margin-left: 0px;
    display: flex;
    position: absolute;
    top: auto;
    bottom: auto;
    left: 10%;
    right: auto;
  }

  .bbox-wrapper-right {
    flex-direction: column;
    align-items: flex-end;
    margin-top: 0px;
    margin-left: 00px;
    display: flex;
    position: absolute;
    bottom: auto;
    left: 50%;
    right: auto;
  }

  .paragraph.red1 {
    font-size: 18px;
  }

  .side-text {
    font-size: 0.75rem;
  }

  .bg-s2 {
    display: flex;
    align-items: center;
  }

  .footer-stage {
    bottom: 5vh;
    font-size: 0.5rem;
  }

  .paragraph.bold {
    color: var(--red);
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    text-transform: uppercase;
  }

  .paragraph.low {
    color: var(--red);
    font-size: 18px;
    line-height: 22px;
    font-family:
      Roboto Condensed,
      sans-serif;
    font-weight: 300;
  }

  .r2-section-gif2 {
    display: flex;
    position: fixed;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
    overflow: hidden;
    align-items: center;
  }

  .r2-section-gif3 {
    display: flex;
    align-items: center;
  }
  .number-input {
    font-size: 25px !important;
  }

  .navbar-brand {
    padding-left: 0;
  }

  .nav-menu {
    flex-direction: row;
    padding-bottom: 0;
    padding-left: 0;
  }

  .nav-link {
    padding-top: 10px;
    padding-bottom: 10px;
    display: inline-block;
  }

  .nav-dropdown {
    flex-direction: column;
    align-items: center;
    display: flex;
  }

  .nav-dropdown-toggle {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .nav-dropdown-list.shadow-three {
    box-shadow: 0 8px 50px rgba(0, 0, 0, 0.05);
  }

  .nav-dropdown-list.shadow-three.w--open {
    position: relative;
  }

  .nav-dropdown-list.shadow-three.mobile-shadow-hide {
    box-shadow: none;
  }

  .mobile-margin-top-10 {
    margin-top: 10px;
  }

  .bot-menu,
  .bot-menu-2,
  .bot-menu-copy {
    flex-direction: row;
    padding-bottom: 0;
    padding-left: 0;
    margin-left: -30px;
  }
}

@media screen and (max-width: 479px) {
  .nav-menu {
    flex-direction: row;
  }

  .nav-button-wrapper {
    width: auto;
  }

  .h2 {
    font-size: 20px;
  }

  .paragraph.red {
    font-size: 18px;
    line-height: 25px;
  }

  .c-box {
    padding: 30px 15px;
  }

  .c-text {
    margin-bottom: 10px;
  }

  .bot-menu {
    flex-direction: row;
  }

  .bot-btn-wrapper {
    width: auto;
  }

  .bot-menu-2,
  .bot-menu-copy {
    flex-direction: row;
  }

  .b-box {
    padding: 30px 15px;
  }

  .p-red.red,
  .p-wallet.red {
    font-size: 18px;
    line-height: 25px;
  }
}

.black-mask {
  position: fixed; /* Cover the entire viewport */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black; /* Black background */
}

.pre-loader {
  position: fixed; /* Cover the entire viewport */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #191119;
}

.pre-loader.fade-in {
  animation: fadeIn 1s forwards;
}

.pre-loader.fade-out {
  animation: fadeOut 1s forwards;
}

.hidden {
  opacity: 0;
  visibility: hidden;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    visibility: hidden;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
}

.fade-in {
  animation: fadeIn 7s ease-out forwards;
}

@keyframes fadeOut {
  from {
    opacity: 1;
    visibility: visible;
  }
  to {
    opacity: 0;
    visibility: hidden;
  }
}

.screen-fade-out {
  animation: fadeOut 1s forwards;
}

.screen-fade-in {
  animation: fadeIn 1s forwards;
}

.fade-out {
  animation: fadeOut 0.5s forwards;
}

.slide-in-right {
  animation: slideInRight 2s ease-in-out forwards;
}

.slide-in-left {
  animation: slideInLeft 2s ease-in-out forwards;
}

.slide-out-left {
  animation: slideOutLeft 2s ease-in-out forwards;
}

.slide-out-right {
  animation: slideOutRight 2s ease-in-out forwards;
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideOutLeft {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

@keyframes slideOutRight {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

.tr-dark {
  transition: transform 7s ease; /* Transition over 2 seconds with ease */
}

.tr-dark-move {
  transform: translateX(-100%); /* Move to the left */
  transition: transform 7s ease;
}

.screen-background {
  z-index: 0;
  background-color: rgba(0, 0, 0, 1);
  display: block;
  position: absolute;
  top: -10%;
  bottom: -10%;
  left: -10%;
  right: -10%;
}

.transition-image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 11;
}

.transition-left {
  animation: slideIn 3s ease;
}

.transition-right {
  animation: slideOut 3s ease;
}

@keyframes slideIn {
  from {
    transform: translateX(-250%);
  }
  to {
    transform: translateX(250%);
  }
}

@keyframes slideOut {
  from {
    transform: translateX(250%);
  }
  to {
    transform: translateX(-250%);
  }
}

.faq-section {
  position: absolute;
  z-index: 10;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: column;
}

.btnwrap {
  display: flex;
  flex-direction: column;
  max-width: 1410px;
  align-items: flex-end;
  position: relative;
  z-index: 105;
}

.popup-content {
  z-index: 101;
  width: 100%;
  max-width: 1410px;
  border: 1px solid var(--red);
  object-fit: fill;
  background-color: rgba(0, 0, 0, 0.95);
  background-image: none;
  flex-direction: column;
  align-items: flex-start;
  padding: 25px 35px 35px 25px;
  display: flex;
  position: static;
  overflow: scroll;
  overflow-x: hidden; /* Hide the horizontal scrollbar */
  overflow-y: scroll; /* Show the vertical scrollbar */
  /* Customize the vertical scrollbar */
  scrollbar-width: thin; /* "thin" for modern browsers, "auto" for others */
  scrollbar-color: #ec0102 transparent; /* Change the scrollbar color */

  /* For Webkit browsers (Chrome, Safari) */
  &::-webkit-scrollbar {
    width: 4px; /* Set the width of the scrollbar */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ec0102; /* Set the color of the scrollbar thumb */
  }
}

.page-title {
  position: relative;
  top: 0;
  color: var(--red);
  right: 0;
  letter-spacing: 3px;
  margin-block-start: 0;
}

.popup-content H2 {
  font-family:
    Roboto Condensed,
    sans-serif;
  font-size: 32px;
  text-transform: uppercase;
  color: var(--red);
  margin-top: 10px;
  padding-right: 20px;
}

.popup-content h3 {
  font-family:
    Roboto Condensed,
    sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  color: var(--red);
  letter-spacing: 2px;
}

.popup-content p {
  font-family:
    Roboto Condensed,
    sans-serif;
  font-size: 20px;
  font-weight: 300;
  line-height: 26px;
  color: var(--red);
}

.number-input-container {
  display: flex;
  align-items: center;
  /* background-color: var(--red); */
  width: 100%;
  justify-content: center;
}

.number-input {
  text-align: center;
  color: black; /* Set the text color to black */
  border: none; /* Remove the default input border */
  background-color: var(--red);
  width: 150px; /* Set a fixed width for the input */
  font-family:
    Roboto Condensed,
    sans-serif;
  font-size: 40px;
  font-weight: 600;
  max-width: 80px;
}

.number-input::-webkit-inner-spin-button,
.number-input::-webkit-outer-spin-button {
  -webkit-appearance: none; /* Remove WebKit styling */
  margin: 0; /* Remove the inner margin */
}

.s-btn {
  padding: 5px 10px;
  color: var(--red);
  background-color: black; /* Set the button background color to white */
  border: 0px solid black; /* Add a border around the button */
  cursor: pointer; /* Change the cursor to pointer on hover */
  font-family:
    Roboto Condensed,
    sans-serif;
  font-size: 40px;
  font-weight: 600;
}

.btc-wallet-wrapper {
  position: absolute;
  width: 100%;
  min-width: 500px;
  height: 100%;
  z-index: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000000cc;
  text-align: center;
}

.eth-wallet-wrapper {
  position: absolute;
  width: 100%;
  min-width: 500px;
  height: 100%;
  z-index: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000000cc;
  text-align: center;
}

.connect-popup-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.wallet-box {
  z-index: 2;
  min-width: 30vh;
  min-height: 20vh;
  border: 1px solid var(--red);
  object-fit: fill;
  background-color: rgba(0, 0, 0, 0.75);
  background-image: none;
  flex-direction: column;
  align-items: stretch;
  padding: 25px 25px 25px 25px;
  display: flex;
  position: static;
}

.wallet-box-wrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 10px;
}

.btn-wallet img {
  max-height: 30px; /* Adjust the icon size as needed */
  margin-right: 10px;
  max-width: 80px;
  margin-top: -2px;
}

.btn-wallet {
  background-color: black;
  color: white;
  font-family:
    Roboto Condensed,
    sans-serif;
  font-size: 20px;
  font-weight: 600;
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  transition: padding 0.3s ease;
  border: 1px solid var(--red);
  text-transform: uppercase;
  text-align: left;
  display: flex;
  align-items: center;
}

.btn-wallet:hover {
  background-color: var(--red);
  color: var(--black);
  font-family:
    Roboto Condensed,
    sans-serif;
  position: relative;
  transition: padding 0.3s ease;
  border: 1px solid var(--red);
}

.error-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000000cc;
  text-align: center;
}

p.error-text {
  color: var(--red);
  font-size: 18px;
  font-weight: 300;
  line-height: 23px;
}

h2.error-text {
  color: var(--red);
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 23px;
  margin-top: 0px;
}

h2.wallet-heading {
  color: var(--red);
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 23px;
  margin-top: 0px;
}

.error-box {
  z-index: 2;
  max-width: 50ch;
  border: 1px solid var(--red);
  object-fit: fill;
  background-color: rgba(0, 0, 0, 0.75);
  background-image: none;
  flex-direction: column;
  align-items: flex-start;
  padding: 25px 25px 25px 25px;
  display: flex;
  position: static;
  top: 27%;
  left: 65%;
}

.pause-box {
  z-index: 2;
  max-width: 50ch;
  object-fit: fill;
  background-color: rgba(0, 0, 0, 0.75);
  background-image: none;
  flex-direction: column;
  align-items: flex-start;
  padding: 25px 25px 25px 25px;
  display: flex;
  position: static;
  top: 27%;
  left: 65%;
}

.show {
  animation: fadeIn 2s ease; /* Adjust duration and timing function as needed */
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes scaleUp {
  from {
    transform: scale(0.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes scaleDown {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(0.5);
    opacity: 0;
  }
}

.faq-section.show {
  animation: scaleUp 0.25s ease-in;
}

.faq-section.hide {
  animation: scaleDown 0.25s ease-out;
}

.btn_connect:disabled {
  color: var(--red);
  background-color: rgba(0, 0, 0, 0);
  font-weight: light;
  font-family:
    Roboto Condensed,
    sans-serif;
  cursor: not-allowed; /* Shows a 'not allowed' cursor on hover */
}

.error-wrapper.hide {
  display: none;
}

/* Validate state change anims */
.validate-state-fade-enter {
  opacity: 0;
  z-index: 1;
}

.validate-state-fade-enter.validate-state-fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

.validate-state-fade-exit {
  opacity: 1;
}

.validate-state-fade-exit.validate-state-fade-exit-active {
  opacity: 0;
  transition: opacity 250ms ease-in;
}

/* Typewriter effect */
.console-text-wrapper {
  white-space: pre-wrap; /* Preserves spacing and line breaks */
  font-family: monospace; /* Gives it a console-like appearance */
}

.console-text {
  border-right: 2px solid;
  animation:
    typewriter 4s steps(40) 1s forwards,
    blinkTextCursor 500ms steps(40) infinite;
}

@keyframes typewriter {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* Cursor blink effect */
@keyframes blinkTextCursor {
  from {
    border-color: transparent;
  }
  to {
    border-color: black;
  }
}

.calculation-container {
  text-align: center;
}

.calculations {
  display: inline-block;
  text-align: left;
  margin-top: 25px;
  width: 60%;
}

.calculation-item {
  margin: 0 auto;
  text-align: center;
}

.amount-controls {
  display: inline-flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 25px;
  align-items: center;
}

.calculation-sum.bordertop {
  color: white; /* Example: Change text color to blue */
  font-size: 20px; /* Example: Set font size to 20 pixels */
  text-align: center;
}

.amount-controls p {
  margin-bottom: 0px;
  text-transform: uppercase;
  font-weight: 600;
}

.bordertop {
  padding: 15px;
  border: 1px solid var(--red);
  margin-top: 15px;
}

.pref {
  color: white;
  font-size: 0.65rem;
  border: 0.5px solid var(--red);
  background: var(--red);
  margin-left: 10px;
  padding-left: 5px;
  padding-right: 5px;
}

@media screen and (min-aspect-ratio: 21/9) {
  /* Your CSS styles for ultra-wide screens here */

  .bot-bar {
    bottom: 4vh;
  }

  .amount-wrapper {
    bottom: 9vh;
  }
}